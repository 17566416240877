import React from 'react';

import Layout from '../components/Layout';

const IndexPage = ({ location }) => (
  <Layout
    location={location}
    title={'Datenschutzerklärung'}
    description={`Die aktuellen Datenschutzbestimmungen zur Nutzung der Webseite.`}
  >
    <style
      dangerouslySetInnerHTML={{
        __html: `
      .inner a { word-break: break-all; text-decoration: underline; }
    `,
      }}
    />
    <article id="main">
      <header>
        <h2>Datenschutz</h2>
        <p></p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <div>
            <h2>Datenschutzerkl&auml;rung</h2>
            <p>
              Verantwortliche Stelle im Sinne der Datenschutzgesetze,
              insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
            </p>
            <br />
            <p>&nbsp;</p>
            <h2>Allgemeiner Hinweis</h2>
            <p>
              Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung
              und die datenschutzrechtlichen Bestimmungen des Bundes
              (Datenschutzgesetz, DSG ) hat jede Person Anspruch auf Schutz
              ihrer Privatsph&auml;re sowie auf Schutz vor Missbrauch ihrer
              pers&ouml;nlichen Daten. Die Betreiber dieser Seiten nehmen den
              Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln
              Ihre personenbezogenen Daten vertraulich und entsprechend der
              gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerkl&auml;rung.
            </p>
            <p>
              In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir
              uns, die Datenbanken so gut wie m&ouml;glich vor fremden
              Zugriffen, Verlusten, Missbrauch oder vor F&auml;lschung zu
              sch&uuml;tzen.
            </p>
            <p>
              Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
              (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
              aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem
              Zugriff durch Dritte ist nicht m&ouml;glich.
            </p>
            <p>
              Durch die Nutzung dieser WebSite erkl&auml;ren Sie sich mit der
              Erhebung, Verarbeitung und Nutzung von Daten gem&auml;ss der
              nachfolgenden Beschreibung einverstanden. Diese WebSite kann
              grunds&auml;tzlich ohne Registrierung besucht werden. Dabei werden
              Daten wie beispielsweise aufgerufene Seiten bzw. Namen der
              abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf
              dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre
              Person bezogen werden. Personenbezogene Daten, insbesondere Name,
              Adresse oder E-Mail-Adresse werden soweit m&ouml;glich auf
              freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine
              Weitergabe der Daten an Dritte.
            </p>
            <p>&nbsp;</p>
            <h2>Bearbeitung von Personendaten</h2>
            <p>
              Personendaten sind alle Angaben, die sich auf eine bestimmte oder
              bestimmbare Person beziehen. Eine betroffene Person ist eine
              Person, &uuml;ber die Personendaten bearbeitet werden. Bearbeiten
              umfasst jeden Umgang mit Personendaten, unabh&auml;ngig von den
              angewandten Mitteln und Verfahren, insbesondere das Aufbewahren,
              Bekanntgeben, Beschaffen, L&ouml;schen, Speichern, Ver&auml;ndern,
              Vernichten und Verwenden von Personendaten.
            </p>
            <p>
              Wir bearbeiten Personendaten im Einklang mit dem schweizerischen
              Datenschutzrecht. Im &Uuml;brigen bearbeiten wir – soweit und
              sofern die EU-DSGVO anwendbar ist – Personendaten gem&auml;ss
              folgenden Rechtsgrundlagen im Zusammenhang mit Art. 6 Abs. 1 DSGVO
              :
            </p>
            <ul>
              <li>
                lit. a) Bearbeitung von Personendaten mit Einwilligung der
                betroffenen Person.
              </li>
              <li>
                lit. b) Bearbeitung von Personendaten zur Erf&uuml;llung eines
                Vertrages mit der betroffenen Person sowie zur Durchf&uuml;hrung
                entsprechender vorvertraglicher Massnahmen.
              </li>
              <li>
                lit. c) Bearbeitung von Personendaten zur Erf&uuml;llung einer
                rechtlichen Verpflichtung, der wir gem&auml;ss allenfalls
                anwendbarem Recht der EU oder gem&auml;ss allenfalls anwendbarem
                Recht eines Landes, in dem die DSGVO ganz oder teilweise
                anwendbar ist, unterliegen.
              </li>
              <li>
                lit. d) Bearbeitung von Personendaten um lebenswichtige
                Interessen der betroffenen Person oder einer anderen
                nat&uuml;rlichen Person zu sch&uuml;tzen.
              </li>
              <li>
                lit. f) Bearbeitung von Personendaten um die berechtigten
                Interessen von uns oder von Dritten zu wahren, sofern nicht die
                Grundfreiheiten und Grundrechte sowie Interessen der betroffenen
                Person &uuml;berwiegen. Berechtigte Interessen sind insbesondere
                unser betriebswirtschaftliches Interesse, unsere Website
                bereitstellen zu k&ouml;nnen, die Informationssicherheit, die
                Durchsetzung von eigenen rechtlichen Anspr&uuml;chen und die
                Einhaltung von schweizerischem Recht.
              </li>
            </ul>
            <p>
              Wir bearbeiten Personendaten f&uuml;r jene Dauer, die f&uuml;r den
              jeweiligen Zweck oder die jeweiligen Zwecke erforderlich ist. Bei
              l&auml;nger dauernden Aufbewahrungspflichten aufgrund von
              gesetzlichen und sonstigen Pflichten, denen wir unterliegen,
              schr&auml;nken wir die Bearbeitung entsprechend ein.
            </p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Cookies</h2>
            <p>
              Diese WebSite verwendet Cookies. Das sind kleine Textdateien, die
              es m&ouml;glich machen, auf dem Endger&auml;t des Nutzers
              spezifische, auf den Nutzer bezogene Informationen zu speichern,
              w&auml;hrend er die Website nutzt. Cookies erm&ouml;glichen es,
              insbesondere Nutzungsh&auml;ufigkeit und Nutzeranzahl der Seiten
              zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren,
              aber auch unser Angebot kundenfreundlicher zu gestalten. Cookies
              bleiben &uuml;ber das Ende einer Browser-Sitzung gespeichert und
              k&ouml;nnen bei einem erneuten Seitenbesuch wieder aufgerufen
              werden. Wenn Sie das nicht w&uuml;nschen, sollten Sie Ihren
              Internetbrowser so einstellen, dass er die Annahme von Cookies
              verweigert.
            </p>
            <p>
              Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
              Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
              Dienste, vor allem im Fall des Trackings, über die
              US-amerikanische Seite{' '}
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noreferrer noopener"
              >
                http://www.aboutads.info/choices/
              </a>{' '}
              oder die EU-Seite{' '}
              <a
                href="http://www.youronlinechoices.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                http://www.youronlinechoices.com/
              </a>{' '}
              erklärt werden. Des Weiteren kann die Speicherung von Cookies
              mittels deren Abschaltung in den Einstellungen des Browsers
              erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls
              nicht alle Funktionen dieses Onlineangebotes genutzt werden
              können.
            </p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung für SSL-/TLS-Verschlüsselung</h2>
            <p>
              Diese WebSite nutzt aus Gründen der Sicherheit und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen,
              die Sie an uns als Seitenbetreiber senden, eine
              SSL-/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
              Sie daran, dass die Adresszeile des Browsers von "http://" auf
              "https://" wechselt und an dem Schloss-Symbol in Ihrer
              Browserzeile.
            </p>
            <p>
              Wenn die SSL bzw. TLS Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Server-Log-Files</h2>
            <p>
              Der Provider dieser WebSite erhebt und speichert automatisch
              Informationen in so genannten Server-Log Files, die Ihr Browser
              automatisch an uns &uuml;bermittelt. Dies sind:
            </p>
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
            </ul>
            <p>
              Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
              Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen. Wir behalten uns vor, diese Daten
              nachtr&auml;glich zupr&uuml;fen, wenn uns konkrete Anhaltspunkte
              f&uuml;r eine rechtswidrige Nutzung bekannt werden.
            </p>
            <p>&nbsp;</p>
            <h2>Haftung für Links</h2>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <p>&nbsp;</p>
            <h2>
              Datenschutzerkl&auml;rung f&uuml;r Recht auf Auskunft,
              L&ouml;schung, Sperrung
            </h2>
            <p>
              Sie haben jederzeit das Recht auf unentgeltliche Auskunft
              &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren
              Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung
              sowie ein Recht auf Berichtigung, Sperrung oder L&ouml;schung
              dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
              personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im
              Impressum angegebenen Adresse an uns wenden.
            </p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Widerspruch Werbe-Mails</h2>
            <p>
              Der Nutzung von im Rahmen der Impressumspflicht
              ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht
              ausdr&uuml;cklich angeforderter Werbung und
              Informationsmaterialien wird hiermit widersprochen. Die Betreiber
              der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im
              Falle der unverlangten Zusendung von Werbeinformationen, etwa
              durch Spam-E-Mails, vor.
            </p>
            <p>&nbsp;</p>
            <h2>Datenschutzerkl&auml;rung f&uuml;r Google Analytics</h2>
            <p>
              Diese WebSite verwendet Google Analytics, einen Webanalysedienst
              von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA. Zur Deaktivierung von Google Analytiscs stellt Google
              unter{' '}
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                rel="noreferrer noopener"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              ein Browser-Plug-In zur Verf&uuml;gung. Google Analytics verwendet
              Cookies. Das sind kleine Textdateien, die es m&ouml;glich machen,
              auf dem Endger&auml;t des Nutzers spezifische, auf den Nutzer
              bezogene Informationen zu speichern. Diese erm&ouml;glichen eine
              Analyse der Nutzung unseres Websiteangebotes durch Google. Die
              durch den Cookie erfassten Informationen &uuml;ber die Nutzung
              unserer Seiten (einschliesslich Ihrer IP-Adresse) werden in der
              Regel an einen Server von Google in den USA &uuml;bertragen und
              dort gespeichert. Wir weisen darauf hin, dass auf dieser Website
              Google Analytics um den Code „gat._anonymizeIp();“ erweitert
              wurde, um eine anonymisierte Erfassung von IP-Adressen (sog.
              IP-Masking) zu gew&auml;hrleisten. Ist die Anonymisierung aktiv,
              k&uuml;rzt Google IP-Adressen innerhalb von Mitgliedstaaten der
              Europ&auml;ischen Union oder in anderen Vertragsstaaten des
              Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum,
              weswegen keine R&uuml;ckschl&uuml;sse auf Ihre Identit&auml;t
              m&ouml;glich sind. Nur in Ausnahmef&auml;llen wird die volle
              IP-Adresse an einen Server von Google in den USA &uuml;bertragen
              und dort gek&uuml;rzt. Google beachtet die Datenschutzbestimmungen
              des „Privacy Shield“-Abkommens und ist beim „Privacy
              Shield“-Programm des US-Handelsministeriums registriert und nutzt
              die gesammelten Informationen, um die Nutzung unserer Websites
              auszuwerten, Berichte für uns diesbez&uuml;glich zu verfassen und
              andere diesbez&uuml;gliche Dienstleistungen an uns zu erbringen.
              Mehr erfahren Sie unter{' '}
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
              .
            </p>
            <p>&nbsp;</p>
            <h2>Google Tag Manager</h2>
            <p>
              Google Tag Manager ist eine L&ouml;sung, mit der wir sog.
              Website-Tags &uuml;ber eine Oberfl&auml;che verwalten k&ouml;nnen
              und so z.B. Google Analytics sowie andere Google-Marketing-Dienste
              in unser Onlineangebot einbinden können. Der Tag Manager selbst,
              welcher die Tags implementiert, verarbeitet keine
              personenbezogenen Daten der Nutzer. Im Hinblick auf die
              Verarbeitung der personenbezogenen Daten der Nutzer wird auf die
              folgenden Angaben zu den Google-Diensten verwiesen.
              Nutzungsrichtlinien:{' '}
              <a
                href="https://www.google.com/intl/de/tagmanager/use-policy.html"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://www.google.com/intl/de/tagmanager/use-policy.html
              </a>
              .
            </p>
            <p>&nbsp;</p>
            <h2>Urheberrechte</h2>
            <p>
              Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos
              oder anderen Dateien auf der Website, geh&ouml;ren ausschliesslich
              dem Betreiber dieser Website oder den speziell genannten
              Rechteinhabern. Für die Reproduktion von sämtlichen Dateien, ist
              die schriftliche Zustimmung des Urheberrechtstr&auml;gers im
              Voraus einzuholen.
            </p>
            <p>
              Wer ohne Einwilligung des jeweiligen Rechteinhabers eine
              Urheberrechtsverletzung begeht, kann sich strafbar und allenfalls
              schadenersatzpflichtig machen.
            </p>
            <p>&nbsp;</p>
            <h2>Allgemeiner Haftungsausschluss</h2>
            <p>
              Alle Angaben unseres Internetangebotes wurden sorgf&auml;ltig
              gepr&uuml;ft. Wir bem&uuml;hen uns, unser Informationsangebot
              aktuell, inhaltlich richtig und vollst&auml;ndig anzubieten.
              Trotzdem kann das Auftreten von Fehlern nicht v&ouml;llig
              ausgeschlossen werden, womit wir keine Garantie f&uuml;r
              Vollst&auml;ndigkeit, Richtigkeit und Aktualit&auml;t von
              Informationen auch journalistisch-redaktioneller Art
              &uuml;bernehmen k&ouml;nnen. Haftungsanspr&uuml;che aus
              Sch&auml;den materieller oder ideeller Art, die durch die Nutzung
              der angebotenen Informationen verursacht wurden, sind
              ausgeschlossen, sofern kein nachweislich vors&auml;tzliches oder
              grob fahrl&auml;ssiges Verschulden vorliegt.
            </p>
            <p>
              Der Herausgeber kann nach eigenem Ermessen und ohne
              Ank&uuml;ndigung Texte ver&auml;ndern oder l&ouml;schen und ist
              nicht verpflichtet, Inhalte dieser Website zu aktualisieren. Die
              Benutzung bzw. der Zugang zu dieser Website geschieht auf eigene
              Gefahr des Besuchers. Der Herausgeber, seine Auftraggeber oder
              Partner sind nicht verantwortlich f&uuml;r Sch&auml;den, wie
              direkte, indirekte, zuf&auml;llige, vorab konkret zu bestimmende
              oder Folgesch&auml;den, die angeblich durch den Besuch dieser
              Website entstanden sind und &uuml;bernehmen hierf&uuml;r folglich
              keine Haftung.
            </p>
            <p>
              Der Herausgeber &uuml;bernimmt ebenfalls keine Verantwortung und
              Haftung f&uuml;r die Inhalte und die Verf&uuml;gbarkeit von
              Webseiten Dritter, die &uuml;ber externe Links dieser Webseite
              erreichbar sind. F&uuml;r den Inhalt der verlinkten Seiten sind
              ausschliesslich deren Betreiber verantwortlich. Der Herausgeber
              distanziert sich damit ausdr&uuml;cklich von allen Inhalten
              Dritter, die m&ouml;glicherweise straf- oder haftungsrechtlich
              relevant sind oder gegen die guten Sitten verstossen.
            </p>
            <p>&nbsp;</p>
            <h2>&Auml;nderungen</h2>
            <p>
              Wir k&ouml;nnen diese Datenschutzerkl&auml;rung jederzeit ohne
              Vorank&uuml;ndigung anpassen. Es gilt die jeweils aktuelle, auf
              unserer Website publizierte Fassung. Soweit die
              Datenschutzerkl&auml;rung Teil einer Vereinbarung mit Ihnen ist,
              werden wir Sie im Falle einer Aktualisierung über die
              &Auml;nderung per E-Mail oder auf andere geeignete Weise
              informieren.
            </p>
            <p>&nbsp;</p>
          </div>
          <hr />
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
